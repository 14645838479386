<template>
  <b-row class="match-height">
    <b-col md="12">
      <b-card title="Identity Verification Statistics">
        <b-card-body>
          <b-row>
            <b-col
              v-for="(item, key) in dashBoard"
              :key="key"
              md="3"
            >
              <b-row>
                <b-avatar
                  :variant="`light-${item.color}`"
                  class="mr-1"
                  size="45"
                >
                  <feather-icon
                    :icon="item.icon"
                    size="21"
                  />
                </b-avatar>
                <div class="truncate">
                  <h2 class="mb-25 font-weight-bolder">
                    {{ item.title }}
                  </h2>
                  <span>{{ item.description }}</span>
                </div>
              </b-row>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
      <h1>{{ user }}</h1>
    </b-col>
  </b-row>
</template>
<script>
import {
  BAvatar, BCard, BCardBody, BCol, BRow,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'

export default {
  name: 'KYC',
  components: {
    BAvatar, BCard, BCardBody, BCol, BRow,
  },
  data() {
    return {
      dashboardInfo: {
        total: 0,
        verified: 0,
        inProgress: 0,
        rejected: 0,
      },
      color: 'primary',
    }
  },
  computed: {
    ...mapGetters({
      listOwnerPermissions: 'users/listOwnerPermissions',
    }),
    dashBoard() {
      return [
        {
          color: 'primary',
          icon: 'UserIcon',
          title: this.dashboardInfo.total,
          description: 'Verification Applications',
        },
        {
          color: 'success',
          icon: 'CheckCircleIcon',
          title: this.dashboardInfo.verified,
          description: 'Verified Applications',
        },
        {
          color: 'warning',
          icon: 'ClockIcon',
          title: this.dashboardInfo.inProgress,
          description: 'Verification In Progress',
        },
        {
          color: 'danger',
          icon: 'XCircleIcon',
          title: this.dashboardInfo.rejected,
          description: 'Rejected Applications',
        },
      ]
    },
  },
  mounted() {
    this.getDashboardInfo()
  },
  methods: {
    async getDashboardInfo() {
      const { data } = await this.$store.dispatch('kyc/fetchDashboardInfo')
      this.dashboardInfo = data
    },
  },
}
</script>
